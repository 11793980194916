// Home.js

import React from "react";
import "./Home.css";
//import MobileStoreButton from "react-mobile-store-button";

const Home = () => {
  return (
      <div className="logo-content">
        <img src={process.env.PUBLIC_URL + "/Artboard1@3x.png"} alt="GolfVideoEditor" /> 
        <div className="caption">GolfVideoEditor (beta)</div>
        <div className="caption">Now available in the Apple App Store!</div>
        {/* <div><MobileStoreButton store="ios" linkProps={{ title: 'iOS Store Button' }}/></div> */}
    </div>
  )
};

export default Home;