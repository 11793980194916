// About.js

import React from "react";
import "./About.css"

const About = () => {
  return (
    <>
      <h1 className="title-about">About GolfVideoEditor</h1>
        <h2 className="about-description">
          GolfVideoEditor is a mobile app that allows you to add graphics to your golf videos to make them more fun to share with your friends. The graphics help give additional context to the your videos and allow the viewer to understand the situation of the golf video. We hope you enjoy the app!
        </h2>
        <h2 className="about-description">If you have any questions about the app or if you have feedback about a new feature you would like to see added, please email us at golfvideoeditor@gmail.com.</h2>
    </>
  );
};

export default About;