// PrivacyPolicy.js

import React from "react";
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <>
      <h1 className = "title-pp">Privacy Policy</h1>
           <h2 className = "subheading">Last Update: January 28, 2023 </h2>
            <p>This privacy policy discloses the policies and practices of Consistent Shot LLC d/b/a GolfVideoEditor <strong>(“GolfVideoEditor,” “we,” or “us”)</strong> concerning information we obtain by and through your use of our mobile application (“Application”) and the website at https://www.golfvideoeditor.com (“Website”) and the services provided through the Application (“Services”).</p>
            <p>We are committed to respecting your privacy and recognizing your need for appropriate protection and management of personally identifiable information and other information you share with us. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use the information you provide to us and to assist you in making informed decisions when using our Application, Website, and/or Services as well as how you can access, review and make choices regarding your information.</p>
            <h2 className = "subheading">Consent</h2>
            <h2 className = "subheading">PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY</h2>
            <p>BY DOWNLOADING, ACCESSING, OR USING THE APPLICATION, WEBSITE, OR SERVICES, AND/OR BY PROVIDING INFORMATION TO US IN CONNECTION WITH THE APPLICATION, WESBITE, OR SERVICES, YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY, AND YOU HEREBY CONSENT TO OUR COLLECTION, USE, AND SHARING OF YOUR INFORMATION AS SET FORTH IN THIS PRIVACY POLICY.</p>
            <p>Your use of the Application, Website, or the Services is also subject to our Terms of Service ("Terms of Service"), which are available at https://www.golfvideoeditor.com/terms-of-service and are expressly incorporated into and made a part of this Privacy Policy by reference. Capitalized terms not defined in this Privacy Policy (if any) have the meanings set forth in the Terms of Service, if applicable.</p>
            <h2 className = "subheading">Changes to this Privacy Policy</h2>
            <p>We may change this Privacy Policy at any time and changes to this Privacy Policy will become effective when we make the revised Privacy Policy available through the Application. We will update the “Last Update” date at the top of this Privacy Policy if we make any such changes to this Privacy Policy. Your use of the Application, Website, or the Services following any change means that you accept the revised Privacy Policy.</p>
            <h2 className = "subheading">Personal Data We Collect Through the Application and Services</h2>
            <p> <strong>At GolfVideoEditor, we understand the importance of protecting your personal information. When using the Application and Services, we do not require users to create accounts, we do not collect any data about your device, we do not collect any data about your location, and we do not store any of the videos you upload or edit during normal use of the Application and Services. Our intention is to collect as little personal information about our users as possible and still run a successful business. </strong> </p>
            <p>Below describes some but not all instances where information may be collected in connection with use of the Application or Services.</p>
            <p>•	Communication with Us. If you were to contact us for any reason, we may collect information from you, such as your name, email address, phone number or mailing address and the content included in your communication.</p>
            <p>•	Promotional Purposes. Outside the normal use of the Application and Services, we may store a video if we saw a video that was created using the Application and Services outside of the Application (e.g. on a social media platform) that might be useful for advertising the Application and Services. If this were the case, we would gain consent from the creator of the edited video and/or person in the video to use the video for promotional purposes. Once consent was received and guidelines around use of the video were discussed, the video and details related to the video such as name, email of the creator and person in the video may be stored on a GolfVideoEditor device.</p>
            <h2 className = "subheading">Personal Data We Collect Through the Website</h2>
            <p>The Website’s main purpose is currently to be a landing page to direct users to the Application and Services, and also provide the Privacy Policy and Terms of Service. There are no Services offered on the Website at this time. We may collect high-level information about Website users including, but not limited to, a Website user’s country in order to understand more about people going to the Website for GolfVideoEditor.</p>
            <p>Below describes some but not all instances where information may be collected in connection with use of the Website.</p>
            <p>•	Communication with Us. If you were to contact us for any reason, we may collect information from you, such as your name, email address, phone number or mailing address and the content included in your communication.</p>
            <p>•	Automatically Collected Information. As you navigate through and interact with the Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
           <p> o	Cookies. Cookies are small files that an application or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the applications or service providers systems to recognize your browser and capture and remember certain information. We may use cookies to understand and save your preferences for future visits and compile aggregate data about traffic and interaction so that we can offer better experiences and tools in the future.</p>
            <p>o	Web Analytics. We may also use third party analytics services such as Google Analytics or Google Adsense to collect information about how you use and interact with our Services and/or Application. Such third party analytics services may use cookies to gather information such as the pages you visited, your IP address, a date/time stamp for your visit and which website referred you to the Application.</p>
            <h2 className = "subheading">How We Use Personal Data</h2>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <p>•	For the purposes for which you specifically provided the information including, without limitation, provide you with the Services, process transactions, and respond to correspondence that we receive from you.</p>
            <p>•	To provide, maintain, administer, or expand the Application, Website, or Services, perform business analyses, or for other internal purposes to develop improved features and functionalities for the Application, Website, or Services, improve or enhance our business, the Services, and other products and services we may offer.</p>
            <p>•	To send you information about your relationship or transactions with us.</p>
            <p>•	To provide information regarding the Application, Services, or Website and any changes to our Terms of Service, this Privacy Policy and other policies.</p>
            <p>•	To notify you about our products, services, and special offers, except that we will not use your personal information for marketing purposes without prior written consent.</p>
           <p> We may use non-personal information for purposes such as measuring the number of users utilizing various features of the Application and making the Application more responsive for users. We may also use non-personal information (for example, statistics regarding use and metrics) for research purposes, for marketing and promotional purposes, and to develop new features and functionality.</p>
            <h2 className = "subheading">How We Share Personal Data</h2>
            <p>Except as otherwise described in this Privacy Policy, or if we inform you otherwise at the time of data collection and receive your consent where required, we will not sell, trade, or share your information with third parties.</p>
            <p>You acknowledge and agree that we may share your information as follows:</p>
            <p>•	With Third-Party Service Providers. We may use other companies to perform services including, without limitation, managing and facilitating some aspects of our Application, Services, or Website such as processing payments, initiating credit transactions, data analysis, and sending emails. These other companies may be supplied with or have access to your personal information solely for the purpose of providing these services to you on our behalf. Such service providers shall be bound by appropriate confidentiality and security obligations.</p>
            <p>•	Required by Law. We also may disclose your personal information in response to a subpoena or similar investigative demand, a court order, or other request from a law enforcement or government agency where required by applicable law.</p>
            <p>•	Sale or Merger. In connection with a corporate transaction, such as the sale of all or a portion of our business, a divestiture, reorganization, merger, consolidation, or asset sale, or in the event of bankruptcy, as required or allowed by law.</p>
            <p>•	Other Circumstances. When disclosure is required or allowed by law in connection with efforts: (i) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; (ii) to protect and defend the rights, property or safety of our company, our users, our employees, or others; (iii) to comply with applicable law or cooperate with law enforcement; (iv) to enforce our Terms of Service or other agreements or policies; or (v) to allow us to pursue available remedies or limit the damages that we may sustain.</p>
            <h2 className = "subheading">Choices Regarding Use of Your Personal Information</h2>
            <p>1.	Disable Cookies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your cookie settings, visit the settings page on your browser. If you disable or refuse cookies, please note that some parts of the Website may then be inaccessible or not function properly.</p>
            <h2 className = "subheading">Children’s Online Privacy Protection Act </h2>
            <p>GolfVideoEditor is committed to complying with the Children's Online Privacy Protection Act (COPPA) and we understand the importance of protecting the privacy of children. Our Application and Service may be used by children under the age of 13, but with the knowledge and consent of a parent or guardian.</p>
            <p>In connection with the Application and Services, we may collect information from users in some instances such as when they reach out to us and communicate feedback with us. We will not use any videos for Promotional Purposes as described in the Personal Data We Collect Through the Application and Services section when the person in the video is under the age of 18 or under the age majority for a given jurisdiction of the promotion if higher than 18. Furthermore, we do not store videos uploaded or edited using the Application or Services for any reason if they contain people under the age of 18. </p>
            <p>In connection with the Website, we may collect information from users in some instances such as when they reach out to us and communicate feedback with us. We also may collect high-level, non-identifying information of all users visiting the Website.</p>
            <p>We do not collect any sensitive personal information from children under the age of 13. We do not share any information collected from children under the age of 13 with any third-party companies or organizations without the knowledge and consent of a parent or guardian.</p>
            <p>If a parent or guardian becomes aware that their child under the age of 13 has provided personal information to us without their consent, they can contact us at golfvideoeditor@gmail.com and request that we delete the information.</p>
            <p>We encourage parents and guardians to supervise their children's online activities and to teach them about safe internet usage. If you are a parent or guardian and have any questions or concerns about our COPPA compliance, please contact us at golfvideoeditor@gmail.com.</p>
            <h2 className = "subheading">International Data Transfers</h2>
            <p>If we obtain personal information about you, we may process and store the information outside of the country in which you are located, including in the United States. The countries in which we process the information may not have the same data protection laws as the country in which you are located. You consent to having your personal data transferred to and processed in the United States.</p>
            <p>We rely upon a number of means to transfer personal information which is subject to the GDPR, including transfer of Personal Information to recipients that have entered into the European Commission approved contract for the transfer of personal data outside the European Economic Area.</p>
            <h2 className = "subheading">California Residents</h2>
            <p>This section supplements our Privacy Policy with respect to the California Consumer Privacy Act (“CCPA”) and certain other California laws and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). Any terms defined in the CCPA have the same meaning when used in this section.</p>
            <p>1.	Information We Collect. We collect certain information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (for the purposes of this section only, “personal information”). Personal information does not include publicly available information, such as deidentified or aggregate consumer information, or information excluded from the CCPA’s scope.</p>
            <p>The following are all of the categories of personal information which we collect, or reasonably believe that Application, Services, or Website have collected through the Services from California Residents, in the twelve (12) months prior to the effective date of this policy (each, a “Category”): No Categories as of this update.</p>
            <p>We obtain each Category directly from you or by way of collection through the Application, service providers and/or payment processors. We share each Category for a business purpose with service providers, payment processors, and other third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.  </p>
            <p>2.	Uses of Personal Information. We may use or disclose the personal information we collect for one or more of the following purposes: (ii) providing customer support; (iii) operating the Application (including without limitation managing third party relationships and enabling usage of our service providers); (iv) communicating with you; (v) customizing the Application; (vi) securing and protecting the Application (including without limitation auditing, bug and fraud detection, debugging and repair of errors and the detection, protection and prosecution of security incidents or illegal activity); (vii) enforcing our terms and policies; (viii) complying with law; and (x) other business purposes about which we may notify you from time to time. </p>
            <p>3.	Sale. In the preceding twelve (12) months, we have not sold personal information as that term is defined based on our understanding of the CCPA and its implementing regulations.</p>
            <p>4.	Requests. Subject to certain exceptions and restrictions, the CCPA provides California consumers the right to submit requests to a business which has collected their personal information (each, a “California Request”). </p>
            <p>•	You have the right to request to know (i) the categories of personal information we have collected about you in the last twelve (12) months, (ii) the categories of sources from which that personal information was collected, the business or commercial purposes for collecting such information.</p>
            <p>•	You have the right to request that we delete the personal information that we have collected or maintain about you. We may deny your request under certain circumstances, such as if we need to comply with our legal obligations or complete a transaction for which your personal information was collected. If we deny your request for deletion, we will let you know the reason why.</p>
            <p>•	If you choose to exercise any of these rights, we will not discriminate against you in anyway. If you exercise certain rights, understand that you may be unable to use or access certain features of our Services or the Application.  </p>
            <p>You may exercise your right to know and your right to deletion twice a year free of charge. To exercise your right to know or your right to deletion, please contact us at golfvideoeditor@gmail.com.</p>
            <p>We will take steps to verify your identity before processing your request to know or request to delete. We will not fulfill your request unless you have provided sufficient information for us to verify you are the individual about whom we collected personal information. If we need to verify your identify, we may request additional information about you to verify your identity. We will only use the personal information provided in the verification process to verify your identity or authority to make a request and to track and document request responses, unless you initially provided the information for another purpose.</p>
            <p>You may use an authorized agent to submit a request to know or a request to delete. When we verify your agent’s request, we may verify both your and your agent’s identity and request a signed document from you that authorizes your agent to make the request on your behalf. To protect your personal information, we reserve the right to deny a request from an agent that does not submit proof that they have been authorized by you to act on their behalf.</p>
            <p>5.	California Do Not Track Disclosure. We do not track our customers over time and across third party websites to provide targeted advertising and therefore do not respond to Do Not Track (DNT) signals. Third parties that have content embedded on our websites such as a social feature may set cookies on a user’s browser and/or obtain information about the fact that a web browser visited our Application from a certain IP address.</p>
            <p>6.	Minors Under 18 in California. If you are a California resident under the age of eighteen (18), and a registered user of this Application, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or information you have publicly posted. To make such a request, please send an email with a detailed description of the specific content or information to golfvideodeitor@gmail.com. Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that there may be circumstances in which the law does not require or allow removal even if requested.</p>
            <p>How to Contact Us</p>
            <p>If you have questions about this policy, or wish to exercise your privacy rights, please contact us at golfvideoeditor@gmail.com.</p>
    </>
  );
};

export default PrivacyPolicy;