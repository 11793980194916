//App.js
import React from 'react';
// import Navbar from './components/NavLink.js';
import Home from './components/Home.js';
import About from './components/About.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import Terms from './components/Terms.js';
import NotFound from './components/NotFound';
import { BrowserRouter, Route, Routes, NavLink } from 'react-router-dom';

function App() {
  return (
    <>
      <BrowserRouter>
        <div style={{
              display: "flex",
              background: 'black',
              padding: '5px 0 5px 5px',
              fontSize: '17px',
              margin: '10px',
              justifyContent: 'center'
          }}>
              <div style={{ margin: '10px' }}>
                  <NavLink to="/" style={({ isActive }) => ({ 
                      color: isActive ? 'lightblue' : 'white' })}>
                      Home
                  </NavLink>
              </div>
              <div style={{ margin: '10px' }}>
                  <NavLink to="/about" style={({ isActive }) => ({ 
                      color: isActive ? 'lightblue' : 'white' })}>
                      About
                  </NavLink>
              </div>
              <div style={{ margin: '10px' }}>
                  <NavLink to="/privacypolicy" style={({ isActive }) => ({ 
                      color: isActive ? 'lightblue' : 'white' })}>
                      Privacy Policy
                  </NavLink>
              </div>
              <div style={{ margin: '10px' }}>
                  <NavLink to="/termsofservice" style={({ isActive }) => ({ 
                      color: isActive ? 'lightblue' : 'white' })}>
                      Terms of Service
                  </NavLink>
              </div>
        </div>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/termsofservice" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  )
};

export default App;

